import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    //type: "dark",
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
});

export const dark = createMuiTheme({
  palette: {
    type: "dark",
  },
});

export const telkeyLight = createMuiTheme({
  palette: {
    primary: {
      light: "#59656e",
      main: "#2f3b43",
      dark: "#07151c",
      contrastText: "#fff",
    },
    secondary: {
      light: "#9fda53",
      main: "#6da81f",
      dark: "#3b7900",
      contrastText: "#000",
    },
  },
});

export const telkeyDark = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#e9f7ff",
      main: "#b7c4ce",
      dark: "#87949d",
      contrastText: "#000",
    },
    secondary: {
      light: "#c7f951",
      main: "#93c611",
      dark: "#609500",
      contrastText: "#000",
    },
  },
});

export default theme;
