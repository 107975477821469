const zxcvbnInputs = [
  "telkey",
  "stuga",
  "hotell",
  "dörr",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "Anna",
  "Eva",
  "Maria",
  "Karin",
  "Kristina",
  "Sara",
  "Lena",
  "Emma",
  "Kerstin",
  "Ingrid",
  "Marie",
  "Malin",
  "Jenny",
  "Annika",
  "Hanna",
  "Linda",
  "Birgitta",
  "Susanne",
  "Elin",
  "Monica",
  "Inger",
  "Sofia",
  "Johanna",
  "Carina",
  "Elisabeth",
  "Ulla",
  "Julia",
  "Katarina",
  "Linnéa",
  "Emelie",
  "Ida",
  "Helena",
  "Camilla",
  "Åsa",
  "Anette",
  "Sandra",
  "Gunilla",
  "Anita",
  "Marianne",
  "Margareta",
  "Anneli",
  "Amanda",
  "Maja",
  "Ann",
  "Therese",
  "Josefin",
  "Cecilia",
  "Jessica",
  "Helen",
  "Lisa",
  "Caroline",
  "Matilda",
  "Barbro",
  "Frida",
  "Ulrika",
  "Elsa",
  "Siv",
  "Alice",
  "Madeleine",
  "Rebecca",
  "Klara",
  "Ebba",
  "Sofie",
  "Gun",
  "Agneta",
  "Berit",
  "Isabelle",
  "Lina",
  "Pia",
  "Wilma",
  "Ella",
  "Yvonne",
  "Louise",
  "Ellen",
  "Britt",
  "Astrid",
  "Mona",
  "Moa",
  "Nathalie",
  "Erika",
  "Alexandra",
  "Emilia",
  "Viktoria",
  "Ann-Christin",
  "Olivia",
  "Alva",
  "Agnes",
  "Felicia",
  "Ann-Marie",
  "Sonja",
  "Britt-Marie",
  "Pernilla",
  "Gunnel",
  "Lovisa",
  "Charlotte",
  "Linn",
  "Lisbeth",
  "Nina",
  "Rut",
  "Mikaela",
  "Lars",
  "Mikael",
  "Anders",
  "Johan",
  "Erik",
  "Per",
  "Karl",
  "Peter",
  "Thomas",
  "Jan",
  "Daniel",
  "Fredrik",
  "Hans",
  "Andreas",
  "Stefan",
  "Mohamed",
  "Mats",
  "Marcus",
  "Mattias",
  "Magnus",
  "Bengt",
  "Jonas",
  "Oskar",
  "Alexander",
  "Niklas",
  "Martin",
  "Bo",
  "Nils",
  "Patrik",
  "Viktor",
  "Björn",
  "Leif",
  "David",
  "Sven",
  "Henrik",
  "Filip",
  "Joakim",
  "Christer",
  "Ulf",
  "Emil",
  "Simon",
  "Christoffer",
  "Anton",
  "Gustav",
  "Robert",
  "Tommy",
  "Kjell",
  "Christian",
  "William",
  "Lucas",
  "Rickard",
  "Håkan",
  "Göran",
  "Rolf",
  "Adam",
  "Lennart",
  "Jakob",
  "Jonathan",
  "Robin",
  "Sebastian",
  "Tobias",
  "Stig",
  "Elias",
  "John",
  "Axel",
  "Linus",
  "Kent",
  "Oliver",
  "Roger",
  "Hugo",
  "Isak",
  "Claes",
  "Jesper",
  "Albin",
  "Jörgen",
  "Rasmus",
  "Ludvig",
  "Jimmy",
  "Max",
  "Ali",
  "Kenneth",
  "Gunnar",
  "Joel",
  "Dennis",
  "Johnny",
  "Josef",
  "Olof",
  "Åke",
  "Olle",
  "Kurt",
  "Liam",
  "Pontus",
  "Leo",
  "Kevin",
  "Samuel",
  "Torbjörn",
  "Felix",
  "Edvin",
  "Gabriel",
  "Arvid",
  "Andersson",
  "Johansson",
  "Karlsson",
  "Nilsson",
  "Eriksson",
  "Larsson",
  "Olsson",
  "Persson",
  "Svensson",
  "Gustafsson",
  "Pettersson",
  "Jonsson",
  "Jansson",
  "Hansson",
  "Bengtsson",
  "Jönsson",
  "Lindberg",
  "Jakobsson",
  "Magnusson",
  "Olofsson",
  "Lindström",
  "Lindqvist",
  "Lindgren",
  "Axelsson",
  "Berg",
  "Bergström",
  "Lundberg",
  "Lind",
  "Lundgren",
  "Lundqvist",
  "Mattsson",
  "Berglund",
  "Fredriksson",
  "Sandberg",
  "Henriksson",
  "Forsberg",
  "Sjöberg",
  "Wallin",
  "Ali",
  "Engström",
  "Mohamed",
  "Eklund",
  "Danielsson",
  "Lundin",
  "Håkansson",
  "Björk",
  "Bergman",
  "Gunnarsson",
  "Holm",
  "Wikström",
  "Samuelsson",
  "Isaksson",
  "Fransson",
  "Bergqvist",
  "Nyström",
  "Holmberg",
  "Arvidsson",
  "Löfgren",
  "Söderberg",
  "Nyberg",
  "Blomqvist",
  "Claesson",
  "Nordström",
  "Mårtensson",
  "Lundström",
  "Ahmed",
  "Viklund",
  "Björklund",
  "Eliasson",
  "Pålsson",
  "Hassan",
  "Berggren",
  "Sandström",
  "Lund",
  "Nordin",
  "Ström",
  "Åberg",
  "Hermansson",
  "Ekström",
  "Falk",
  "Holmgren",
  "Dahlberg",
  "Hellström",
  "Hedlund",
  "Sundberg",
  "Sjögren",
  "Ek",
  "Blom",
  "Abrahamsson",
  "Martinsson",
  "Öberg",
  "Andreasson",
  "Strömberg",
  "Månsson",
  "Åkesson",
  "Hansen",
  "Norberg",
  "Lindholm",
  "Dahl",
  "Jonasson",
  "123456",
  "12345",
  "knulla",
  "qwerty",
  "666666",
  "hejsan",
  "stilet",
  "123456789",
  "12345678",
  "password",
  "mamma",
  "volvo",
  "sommar",
  "hejhej",
  "niklas",
  "bajskorv",
  "gnaget",
  "artto",
  "kungen",
  "kalle",
  "sverige",
  "general",
  "kalleanka",
  "kuken",
  "123123",
  "cocacola",
];

export default zxcvbnInputs;
